import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {helpers} from "../../components/helpers";

/*Images*/
import IconLogoMenewCircle from "../../components/Icons/icon-logo-menew-circle";
import IconArrowLeft from "../../components/Icons/icon-arrow-left";
import ReloadControll from "../../components/reload-controll";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";

const Produto = (props) => {

    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        edit: !!props.location.state ? props.location.state.edit : false,
        produto: !!props.location.state ? props.location.state.produto : {},
    });

    useEffect(() => {
        dispatch({
            type: 'ANOTHERS', anothers: {
                anchorInicio: false,
                carouselCategorias: {
                    carousel: `#carousel_${helpers.collection().snakeCase(state.produto.categoriaNome)}`,
                    item: `#${helpers.collection().snakeCase(state.produto.produtoNome)}`,
                }
            }
        });
        setState(state => ({
            ...state,
            produto: {
                ...state.produto,
                qtdSelected: state.produto.qtdSelected === 0 ? 1 : state.produto.qtdSelected
            }
        }));
    }, [dispatch, state.produto.categoriaNome, state.produto.produtoNome]);

    const handleObservacao = (e, produto) => {
        const element = e.currentTarget;
        produto.produtoObservacao = element.value;
    };

    const handleClickMinus = async (e) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        if (state.produto.qtdSelected === 1) {
            return false;
        }
        state.produto.qtdSelected--;
        setState({...state});
    };

    const handleClickPlus = async (e) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        state.produto.qtdSelected++;
        setState({...state});
    };

    // const handleChange = (e) => {
    //     if (e.currentTarget.value > 0) {
    //         state.produto.qtdSelected = parseInt(e.currentTarget.value);
    //         setState({...state});
    //     }
    // };

    const handleClickAdicionar = () => {
        if (!store.app.services.estabelecimento.em_funcionamento && !helpers.isCardapio()) {
            helpers.toast.info('Por favor, verifique nosso horario de funcionamento, obrigado.');
            return false;
        }

        if (state.edit) {
            history.goBack();
            return false;
        }

        const pedido = {
            categoriaCodigo: state.produto.categoriaCodigo,
            categoriaNome: state.produto.categoriaNome,
            eComplemento: state.produto.eComplemento,
            categoriaOculto: state.produto.categoriaOculto,
            produtos: [state.produto]
        };
        const produtos = [];
        produtos.push(helpers.collection().cloneDeep(pedido));
        dispatch({type: 'PEDIDO_PRODUTOS_INDIVIDUAL', produtos});
        history.goBack();
        return false;
    };

    if (!store.app.services.loaded || store.app.services.estabelecimento.slug !== idestabelecimento) {
        history.push(`/${idestabelecimento}`);
        return false;
    }

    if (helpers.collection().isEmpty(state.produto)) {
        history.push(`/${idestabelecimento}/home#inicio`);
        return false;
    }

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    return (
        <div className="d-flex flex-column justify-content-between scroll">

            <div className="min-height-170px flex-shrink-0 flex-grow-0 d-flex flex-column text-white" style={{
                backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
            }}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="p-2"
                         onClick={e => {
                             history.goBack()
                         }}>
                        <IconArrowLeft width={35}/>
                    </div>
                    <div className="pt-2 pr-3 pb-2 pl-2">
                        <IconLogoMenewCircle width={38}/>
                    </div>
                </div>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-0 d-flex align-items-center justify-content-center"
                 style={{marginTop: '-100px'}}>
                <div className="elevation-3" style={{
                    width: "195px",
                    height: "195px",
                    backgroundImage: !!state.produto.produtoFoto
                        ? `url(https://d3tn6f63tltlff.cloudfront.net/old-tmp/${state.produto.produtoFoto})`
                        : 'url(/images/sem-imagem.png)',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: 'repeat',
                    backgroundColor: '#fff',
                    borderRadius: "10px"
                }}/>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">
                <div className="p-2 text-break">
                    <span className="text-lg">
                    {state.produto.produtoNome}
                </span>
                </div>
                <div className="p-2 d-flex align-items-center justify-content-between">

                    <span className="text-xl" style={{
                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                    }}>{helpers.converteMoeda(state.produto.preco)}</span>

                    <div className="width-100px d-flex align-items-center position-relative">

                        <i className="fas fa-minus position-absolute left-0 ml-2 p-1" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}
                           onClick={handleClickMinus}/>

                        <div className="form-control form-control-md text-center border-radius-10">
                            {state.produto.qtdSelected}
                        </div>

                        <i className="fas fa-plus position-absolute right-0 mr-2 p-1" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}
                           onClick={handleClickPlus}/>
                    </div>

                </div>
                <div className="p-2 mt-4 text-justify text-break">
                    <span className="text-muted">
                        {state.produto.produtoDescricao}
                    </span>
                </div>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">
                <div className="p-2">
                    <span className="text-bold">Observações:</span>
                </div>
                <div className="p-2">
                    <textarea rows={6} cols={30} className="form-control border-radius-15"
                              defaultValue={state.produto.produtoObservacao}
                              onChange={e => {
                                  handleObservacao(e, state.produto)
                              }}
                              id="produtoObservacao" placeholder="Observações..."/>
                </div>
            </div>


            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-around">
                <div className="text-bold text-md btn btn-block"
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.goBack();
                     }}>
                    Cancelar
                </div>
                <div className="p-2 text-bold text-md btn btn-block border-radius-20
                 d-flex align-items-center justify-content-around" style={{
                    backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                    color: '#fff',
                }}
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         handleClickAdicionar();
                     }}>
                    <div className="p-2">Adicionar</div>
                    <div className="p-2">{helpers.converteMoeda(state.produto.preco * state.produto.qtdSelected)}</div>
                </div>
            </div>

        </div>
    );
};

export default Produto;
