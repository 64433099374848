import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {helpers} from "../../components/helpers";

/*Layout*/
import Footer from "../../components/layout/footer";

/*Components*/
import ReloadControll from '../../components/reload-controll';
import CarrouselMaisPedidos from './carousel-mais-pedidos';
import CarrouselCategorias from './carousel-categorias';
import EstabelecimentoIndisponivel from '../../components/estabelecimentoIndisponivel';

/*Icons*/
import IconLogoMenewCircle from "../../components/Icons/icon-logo-menew-circle";
import IconSearch from "../../components/Icons/icon-search";
import IconFechar from "../../components/Icons/icon-fechar";

const Home = (props) => {

    const store = useSelector(store => store);
    const history = useHistory();
    const {idestabelecimento} = useParams();
    const [state, setState] = useState({data: []});

    let categsProdsGeral = helpers.collection().cloneDeep(store.app.services.produtosCategorias);

    const setLocation = useCallback(async () => {
        const t1 = helpers.dot.get(store.app.modules.anothers, 'data.carouselCategorias.carousel');
        const t2 = helpers.dot.get(store.app.modules.anothers, 'data.carouselCategorias.item');
        const inicio = helpers.dot.get(store.app.modules.anothers, 'data.anchorInicio');
        if (inicio) {
            window.location.href = `/${idestabelecimento}/home#inicio`;
        } else {
            if (!!t1 && !!t2) {
                window.location.href = `/${idestabelecimento}/home${t1}`;
                await helpers.awaitForTime(200);
                window.location.href = `/${idestabelecimento}/home${t2}`;
            }
        }
    }, [store.app.modules.anothers, idestabelecimento]);
    useEffect(() => {
        setLocation()
    }, [setLocation]);

    let HandleFilterChange = e => {

        let produtos = []

        categsProdsGeral.map(function (categ) {
            if (categ.categoriaOculto !== 'S' && categ.eComplemento !== 'S') {
                categ.produtos.map(function (prod) {
                    if ((prod.produtoNome && prod.produtoNome.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
                        || (prod.categoriaNome && prod.categoriaNome.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
                    ) {
                        produtos.push(prod);
                    }
                    return true;
                });
                return true;
            }
            return true;
        });

        const {value} = e.target;

        setState(state => ({
            ...state,
            str_filter_prods: value,
            data_filtered_prods: produtos
        }));

    };

    const clearSearch = () => {
        document.getElementById("buscaProduto").value = "";
        setState(state => ({
            ...state,
            str_filter_prods: "",
            data_filtered_prods: []
        }));
    };

    const produtoSelected = (produto) => {
        if (produto.temKit === 'S' || produto.temPizza === 'S' || produto.temLansec === 'S' || (produto.pedeComplemento === 'S' && !!produto.cComplemento)) {
            history.push(`/${idestabelecimento}/produto-complemento`, {produto: produto});
            return false;
        }
        history.push(`/${idestabelecimento}/produto`, {produto: produto});
    };

    useEffect(() => {
        document.body.className = 'hold-transition';
    }, []);

    if (!store.app.services.loaded || store.app.services.estabelecimento.slug !== idestabelecimento) {
        history.push(`/${idestabelecimento}`);
        return false;
    }

    //////

    let handleScroll = function (event) {

        let scrollY = (document.getElementById("contentInicial").scrollTop);
        let top = 500;

        const destaques = (store.app.services.estabelecimento.destaques.length > 0);
        const maisVendidos = (store.app.services.estabelecimento.mais_vendidos.length > 0);

        if (!destaques || !maisVendidos) {
            top = 260;
        }

        if (!destaques && !maisVendidos) {
            top = 1;
        }

        if (scrollY >= top) {
            setState(state => ({
                ...state,
                fixedCarrouselCategorias: true
            }));
        } else {
            setState(state => ({
                ...state,
                fixedCarrouselCategorias: false
            }));
        }

    };

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    return (

        <div className="d-flex flex-column scroll" onScroll={handleScroll}>

            <div className="mb-0 flex-grow-0 text-white" style={{
                backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                height: !state.fixedCarrouselCategorias ? "140px" : "115px",
                transition: "height 1s",
                zIndex: 10
            }}>
                <div className="d-flex align-items-center justify-content-between"
                     style={{height: !state.fixedCarrouselCategorias ? "104px" : "auto"}}>

                    <div className="pt-2 pr-2 pb-0 pl-2 mb-0 d-flex align-items-center justify-content-center"
                         style={{height: !state.fixedCarrouselCategorias ? "auto" : "75px"}}>

                        {(store.app.services.estabelecimento.logomarca) &&

                        <div>
                            <div className="mt-2 mr-1 mb-2 ml-2 elevation-3" style={{
                                width: !state.fixedCarrouselCategorias ? "80px" : "45px",
                                height: !state.fixedCarrouselCategorias ? "80px" : "45px",
                                backgroundImage: !!store.app.services.estabelecimento.logomarca
                                    ? `url(${store.app.services.estabelecimento.logomarca})`
                                    : 'url(/images/sem-imagem.png)',
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: '#fff',
                                borderRadius: !state.fixedCarrouselCategorias ? "10px" : "6px",
                                transition: "width 1s, height 1s"
                            }}/>
                        </div>

                        }

                        <div className="pt-2 pr-2 pb-2 pl-1 d-flex flex-column" style={{lineHeight: "17px"}}>

                            <span className="text-lg text-bold">{store.app.services.estabelecimento.descricao}</span>

                            {/*
                            <span style={{display:state.fixedCarrouselCategorias?"none":"flex"}} className="text-md">{store.app.services.estabelecimento.ramo_atuacao}</span>
                            */}

                            <span className="text-sm mt-1">
                                <span style={{
                                    float: "left",
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: store.app.services.estabelecimento.em_funcionamento ? "#0F0" : "#F00",
                                    marginTop: "2px",
                                    marginRight: "2px",
                                    border: "1px solid #fff",
                                    borderRadius: "10px"
                                }}></span>
                                {(store.app.services.estabelecimento.em_funcionamento) ? <span>Aberto</span> :
                                    <span>Fechado</span>}
                            </span>

                            <span style={{display: state.fixedCarrouselCategorias ? "none" : "flex"}}
                                  className="text-sm">
                                {
                                    store.app.services.estabelecimento.horario.map((horario, _index3) => (
                                        <span key={_index3}>{(_index3 > 0) &&
                                        <b>&nbsp;-</b>} {horario.hora_abertura.substr(0, 5)} às {horario.hora_fechamento.substr(0, 5)}</span>
                                    ))
                                }
                            </span>

                            {store.app.services.estabelecimento.prazo_entrega &&
                            <span style={{display: state.fixedCarrouselCategorias ? "none" : "flex"}}
                                  className="text-sm">
                                    Entrega em: {store.app.services.estabelecimento.prazo_entrega}
                                </span>
                            }

                        </div>

                    </div>

                    <div className="pt-2 pr-3 pb-2 pl-1">
                        <IconLogoMenewCircle width={35}/>
                    </div>

                </div>

                <div className="p-2 mb-4 ml-2 mr-2 d-flex align-items-center">
                    <input id="buscaProduto" type="text"
                           className="p-3 form-control form-control-lg mr-n4 text-md elevation-2"
                           onChange={HandleFilterChange} placeholder="Busque seu produto"/>

                    {(state.str_filter_prods === undefined || state.str_filter_prods.length === 0) && (
                        <IconSearch className="ml-n3" width={25}
                                    fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    )}

                    {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && (
                        <div onClick={clearSearch}>
                            <IconFechar className="ml-n3" width={25}
                                        fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        </div>
                    )}

                </div>

            </div>

            <div id="contentInicial"
                 className="pt-0 pr-0 pb-2 pl-0 flex-grow-1 d-flex flex-column scroll position-relative"
                 style={{
                     marginTop: state.fixedCarrouselCategorias && (state.str_filter_prods === undefined || state.str_filter_prods.length === 0) ? '95px' : (state.fixedCarrouselCategorias && (state.str_filter_prods !== undefined || state.str_filter_prods.length !== 0) ? "0px" : "auto")
                 }}>
                <div id="inicio"/>

                {(state.str_filter_prods === undefined || state.str_filter_prods.length === 0) && (

                    <>

                        {/*
                        <div className="p-2">
                            <div className="p-1 ml-2">
                                <span className="text-bold">Promoções </span>
                            </div>
                            <div className="p-0">
                                <img className="img-fluid w-100" src={Banner} alt=""/>
                            </div>
                        </div>
                        */}

                        {(store.app.services.estabelecimento.destaques.length > 0) &&

                        <div className="mt-4 pt-3 pb-0 pr-0 pl-0">
                            <div className="mr-1 ml-3 d-flex align-items-center justify-content-between">
                                <span className="text-bold">Destaques</span>
                                {/*<span className="text-muted text-bold pr-2">Ver todos</span>*/}
                            </div>
                            <div className="p-1">
                                <CarrouselMaisPedidos opcao="destaques"/>
                            </div>
                        </div>

                        }

                        {(store.app.services.estabelecimento.mais_vendidos.length > 0) &&

                        <div
                            className={"pb-0 pr-0 pl-0 " + (store.app.services.estabelecimento.destaques.length === 0 ? "pt-3 mt-4" : "")}>
                            <div className={" ml-3 d-flex align-items-center justify-content-between"}>
                                <span className="text-bold">Mais pedidos</span>
                                {/*<span className="text-muted text-bold pr-2">Ver todos</span>*/}
                            </div>
                            <div className="p-1">
                                <CarrouselMaisPedidos opcao="mais_vendidos"/>
                            </div>
                        </div>

                        }

                        <div className="mt-4">

                            <div className="pt-0 pr-2 pb-0 pl-2" style={state.fixedCarrouselCategorias ? {
                                position: "fixed",
                                width: "100%",
                                height: "45px",
                                top: "125px",
                                borderBottom: "1px solid #e8e8e8",
                                backgroundColor: "#F8FAFC"
                            } : {
                                borderTop: "1px solid #e8e8e8",
                                borderBottom: "1px solid #e8e8e8",
                                backgroundColor: "#ffffff"
                            }}>
                                {/*<div className="p-1 ml-2 d-flex align-items-center justify-content-between">
                                    <span className="text-bold">Categorias</span>
                                    <span className="text-muted text-bold"
                                        onClick={async e => {
                                            await helpers.animatedElement(e, 'bounceIn', 'fast');
                                            await helpers.awaitForTime(500);
                                            history.push(`/${idestabelecimento}/produtos`)
                                        }}>Ver todas</span>
                                </div>*/}
                                <div className="p-0">
                                    <CarrouselCategorias/>
                                </div>
                            </div>

                        </div>

                    </>
                )}

                {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && state.data_filtered_prods.length === 0 && (

                    <div>
                        <div className="p-2">
                            <div className="p-1 ml-2">
                                <strong>Nenhum produto encontrado...</strong>
                            </div>
                        </div>
                    </div>

                )}

                {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && state.data_filtered_prods.length > 0 && (

                    <div>
                        <div className="p-2">
                            <div className="p-1 ml-2">
                                {
                                    state.data_filtered_prods.map(produto => (
                                        <div key={produto.produtoCodigo}>
                                            <div
                                                className="pt-2 pr-0 pb-2 pl-0 d-flex align-items-center justify-content-between"
                                                onClick={e => {
                                                    produtoSelected(produto)
                                                }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="pt-2 pr-1 pb-2 pl-0">
                                                        <div className="elevation-3" style={{
                                                            width: "95px",
                                                            height: "95px",
                                                            backgroundImage: !!produto.produtoFoto
                                                                ? `url(https://d3tn6f63tltlff.cloudfront.net/old-tmp/${produto.produtoFoto})`
                                                                : 'url(/images/sem-imagem.png)',
                                                            backgroundPosition: "center",
                                                            backgroundSize: "cover",
                                                            backgroundRepeat: 'repeat',
                                                            backgroundColor: '#fff',
                                                            borderRadius: "10px"
                                                        }}/>
                                                    </div>
                                                    <div className="pt-2 pr-0 pb-2 pl-2 d-flex flex-column">
                                                        <div className="p-1 mb-n2 text-bold">
                                                            <span>
                                                                {helpers.collection().truncate(produto.produtoNome,
                                                                    {length: 50, omission: '...'})}
                                                            </span>
                                                        </div>
                                                        <div className="p-1 text-sm text-muted">
                                                            <span style={{wordBreak: "break-word"}}>
                                                                {helpers.collection().truncate(produto.produtoDescricao,
                                                                    {length: 120, omission: '...'})}
                                                            </span>
                                                        </div>
                                                        <div className="p-1">
                                                            <span style={{
                                                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                            }}>
                                                                {
                                                                    produto.temKit === 'S' || produto.temPizza === 'S' || produto.pedeComplemento === 'S'
                                                                        ? helpers.produto.getMenorPrecoProdutoComplemento(produto, store)
                                                                        : helpers.converteMoeda(produto.preco)
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*
                                                <IconArrowRight width={35}
                                                                fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                                */}
                                            </div>
                                            <hr className="w-100"/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                )}

                {(state.str_filter_prods === undefined || state.str_filter_prods === "") && (

                    <div id="listaProdutos" className="pt-4 pl-2 pr-2 pb-2 position-relative">

                        {
                            categsProdsGeral.map(categ => {

                                if (categ.categoriaOculto !== 'S' && categ.eComplemento !== 'S') {

                                    return <div key={categ.categoriaCodigo} className="p-1 ml-2"
                                                id={helpers.collection().snakeCase(categ.categoriaNome)}>
                                        <span className="text-bold">{categ.categoriaNome}</span>
                                        <div className="pt-1">
                                            {
                                                categ.produtos.map(produto => (
                                                    <div key={produto.produtoCodigo}
                                                         id={helpers.collection().snakeCase(produto.produtoNome)}>
                                                        <div
                                                            className="pt-0 pr-0 pb-0 pl-0 d-flex align-items-center justify-content-between"
                                                            onClick={e => {
                                                                produtoSelected(produto)
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                                <div className="pt-2 pr-1 pb-2 pl-0">
                                                                    <div className="elevation-3" style={{
                                                                        width: "95px",
                                                                        height: "95px",
                                                                        backgroundImage: !!produto.produtoFoto
                                                                            ? `url(https://d3tn6f63tltlff.cloudfront.net/old-tmp/${produto.produtoFoto})`
                                                                            : 'url(/images/sem-imagem.png)',
                                                                        backgroundPosition: "center",
                                                                        backgroundSize: "cover",
                                                                        backgroundRepeat: 'repeat',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: "10px"
                                                                    }}/>
                                                                </div>
                                                                <div className="pt-2 pr-0 pb-2 pl-2 d-flex flex-column">
                                                                    <div className="p-1 mb-n2 text-bold">
                                                                        <span>
                                                                            {helpers.collection().truncate(produto.produtoNome,
                                                                                {length: 50, omission: '...'})}
                                                                        </span>
                                                                    </div>
                                                                    <div className="p-1 text-sm text-muted">
                                                                        <span style={{wordBreak: "break-word"}}>
                                                                            {helpers.collection().truncate(produto.produtoDescricao,
                                                                                {length: 120, omission: '...'})}
                                                                        </span>
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <span style={{
                                                                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                        }}>
                                                                            {
                                                                                produto.temKit === 'S' || produto.temPizza === 'S' || produto.pedeComplemento === 'S'
                                                                                    ? helpers.produto.getMenorPrecoProdutoComplemento(produto, store)
                                                                                    : helpers.converteMoeda(produto.preco)
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*
                                                            <IconArrowRight width={35}
                                                                            fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                                            */}
                                                        </div>
                                                        <hr className="w-100"/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                }
                                return true;
                            })

                        }

                    </div>

                )}

            </div>

            <Footer/>

        </div>

    );

};

export default Home;
