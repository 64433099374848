import React from 'react';
import {useSelector} from "react-redux";
// import Slider from 'react-slick';
import {helpers} from "../../components/helpers";
import {useHistory, useParams} from 'react-router-dom';

const CarrouselMaisPedidos = (props) => {

    const history = useHistory();

    const store = useSelector(store => store);

    const {idestabelecimento} = useParams();

    // const settings = {
    //     accessibility: false,
    //     adaptiveHeight: false,
    //     centerMode: false,
    //     className: "slider variable-width",
    //     // className: "center",
    //     // centerPadding: "50px",
    //     dots: false,
    //     arrows: false,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     swipeToSlide: true,
    //     // autoplay: true,
    //     pauseOnHover: false,
    //     variableWidth: false,
    //     cssEase: 'linear',
    //     lazyLoad: 'progressive',
    //     edgeFriction:0,
    //     beforeChange: (current, next) => {
    //         // console.log(current, next);
    //     },
    //     afterChange: (current, next) => {
    //         // console.log(current, next);
    //     },
    // };

    const rows = [];

    const prods = [];

    let categsProdsGeral = helpers.collection().cloneDeep(store.app.services.produtosCategorias);


    categsProdsGeral.map(function (categ) {
        categ.produtos.map(function (prod) {
            if (store.app.services.estabelecimento[props.opcao].indexOf(prod.produtoCodigo) >= 0) {
                prods.push(prod);
            }
            return true;
        });
        return true;
    });

    store.app.services.estabelecimento[props.opcao].map(function (codigo) {
        prods.map(function (prod) {
            if (codigo === prod.produtoCodigo) {
                rows.push(prod);
            }
            return true;
        });
        return true;
    });

    const produtoSelected = (produto) => {
        if (produto.temKit === 'S' || produto.temPizza === 'S' || produto.temLansec === 'S' || (produto.pedeComplemento === 'S' && !!produto.cComplemento)) {
            history.push(`/${idestabelecimento}/produto-complemento`, {produto: produto});
            return false;
        }
        history.push(`/${idestabelecimento}/produto`, {produto: produto});
    };

    const rowsRender = (item) => {
        return <div key={item.produtoCodigo} className="p-2 m-0"
                    style={{width: "40%", height: "160px", flex: "0 0 33.333333%"}}>
            <div className="d-flex flex-column" onClick={e => {
                produtoSelected(item)
            }} style={{width: "100%", height: "160px"}}>
                <div className="flex-fill">
                    <div style={{
                        width: "100%",
                        height: "80px",
                        backgroundImage: item.produtoFoto ? "url(https://d3tn6f63tltlff.cloudfront.net/old-tmp/" + item.produtoFoto + ")" : "url(/images/sem-imagem.png)",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: 'repeat',
                        backgroundColor: '#fff',
                        borderRadius: "10px 10px 0px 0px"
                    }}/>
                </div>
                <div
                    className="p-2 flex-fill d-flex flex-column align-items-center justify-content-around
                     border border-bottom-radius-10 text-center elevation-2 min-height-100px">
                    <span className="text-normal">
                        {helpers.collection().truncate(item.produtoNome,
                            {length: 40, omission: ' ...'})}
                    </span>
                    <span className="text-bold"
                          style={{
                              color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                          }}>
                        {
                            item.temKit === 'S' || item.temPizza === 'S' || item.pedeComplemento === 'S'
                                ? helpers.produto.getMenorPrecoProdutoComplemento(item, store)
                                : helpers.converteMoeda(item.preco)
                        }
                    </span>
                </div>
            </div>
        </div>
    };

    return (
        <div style={{
            width: "100%",
            height: "205px",
            display: "flex",
            position: "relative",
            overflow: "auto",
        }}>
            {
                rows.map((row) => (rowsRender(row)))
            }
        </div>
    );
};

export default CarrouselMaisPedidos;
