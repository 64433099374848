import React, {useCallback, useEffect, useState} from 'react';
import IconArrowLeft from "../../components/Icons/icon-arrow-left";
import IconLogoMenewCircle from "../../components/Icons/icon-logo-menew-circle";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {helpers} from "../../components/helpers";
import ReloadControll from "../../components/reload-controll";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";

const ProdutoComplemento = (props) => {
    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state] = useState({
        edit: !!props.location.state ? props.location.state.edit : false,
        produto: !!props.location.state ? props.location.state.produto : {},
    });

    const init = useCallback(() => {
        let montagem = [];
        if (!!state.edit) {
            montagem = [state.edit];
        } else {
            if (!state.produto) {
                return false;
            }

            const data = [];
            const sabores = [];
            const complementos = [];
            const produtosCategorias = helpers.collection().cloneDeep(store.app.services.produtosCategorias);

            /*Recupera os complementos da pizza selecionada*/
            if (state.produto.temPizza === 'S') {
                const pizzaCloned = helpers.collection().cloneDeep(store.app.services.pizza);
                const pizzas = pizzaCloned.filter(pizza => {
                    return pizza.codigo === state.produto.produtoCodigo;
                });

                pizzas.map(pizza => {
                    const filterSabores = helpers.collection().filter(produtosCategorias, {categoriaCodigo: pizza.sabores});
                    filterSabores.map(fSabores => {
                        fSabores.inf = 1;/*Regra: Sempre será no mínimo 1*/
                        fSabores.sup = pizza.limite;
                        fSabores.qtdSelected = 0;
                        fSabores.sabores = true;
                        fSabores.adicionais = false;
                        sabores.push(filterSabores[0]);
                        return true;
                    });

                    let compl = helpers.collection().split(pizza.compl, ',');
                    compl = compl.map(number => {
                        return helpers.collection().parseInt(number)
                    });

                    const complLimites = helpers.collection().split(pizza.compl_limite, '|');

                    compl.map((c, limitIndex) => {
                        const filterComplementos = helpers.collection().filter(produtosCategorias, {categoriaCodigo: c});
                        filterComplementos.map(fComplemento => {
                            let limites = complLimites[limitIndex];
                            limites = helpers.collection().split(limites, ',');
                            if (limites.length > 0) {
                                limites = limites.map(number => {
                                    return helpers.collection().parseInt(number)
                                });
                            }
                            fComplemento.inf = helpers.collection().get(limites, '0');
                            fComplemento.sup = helpers.collection().get(limites, '1');
                            fComplemento.qtdSelected = 0;
                            fComplemento.sabores = false;
                            fComplemento.adicionais = true;
                            complementos.push(fComplemento);
                            return true;
                        });
                        return true;
                    });
                    return true;
                });

                data.push(...sabores, ...complementos);
            }

            /*Recupera os Complementos produto "pedeComplemento" selecionado*/
            if (state.produto.pedeComplemento === 'S' && !!state.produto.cComplemento) {
                const complementos = helpers.collection().filter(produtosCategorias, {categoriaCodigo: state.produto.cComplemento});
                helpers.collection().set(complementos, '0.inf', state.produto.tmin);
                helpers.collection().set(complementos, '0.sup', state.produto.tmax);
                helpers.collection().set(complementos, '0.qtdSelected', 0);
                if (complementos.length > 0) {
                    data.push(complementos[0]);
                }
            }

            /*Recupera os kits do produto selecionado*/
            if (state.produto.temKit === 'S') {
                const kitsCloned = helpers.collection().cloneDeep(store.app.services.kits);
                const kits = kitsCloned.filter(kit => {
                    return kit.codigo === state.produto.produtoCodigo;
                });

                kits.map(kit => {
                    for (let i = 1; i <= 12; i++) {
                        const filter = helpers.collection().filter(produtosCategorias, {categoriaCodigo: kit['categ' + i]});
                        if (filter.length > 0) {
                            const prodCat = filter[0];
                            prodCat.inf = kit['inf' + i];
                            prodCat.sup = kit['sup' + i];
                            prodCat.qtdSelected = 0;
                            data.push(helpers.collection().cloneDeep(prodCat));
                        }
                    }
                    return true;
                });
            }

            /*Recupera os Lansec do produto selecionado*/
            if (state.produto.temLansec === 'S' && state.produto.temKit === 'N' && state.produto.temPizza === 'N') {
                const lansecCloned = helpers.collection().cloneDeep(store.app.services.lansec);
                const lansec = lansecCloned.filter(lansec => {
                    return lansec.lansec_codigo === state.produto.produtoCodigo;
                });

                lansec.map(lansec => {

                    lansec.produtos.map(item => {
                        item.qtdSelected = 0;
                    })
                    const prodCat = lansec;
                    prodCat.categoriaNome = lansec.lansec_titulo;
                    prodCat.inf = lansec.lansec_minimo;
                    prodCat.sup = lansec.lansec_maximo
                    prodCat.qtdSelected = 0;
                    data.push(helpers.collection().cloneDeep(prodCat));

                    if(lansec.produtosAdic.length > 0){

                        lansec.produtosAdic.map(item => {
                            item.qtdSelected = 0;
                        })

                        const prodCat = lansec;
                        prodCat.categoriaNome = lansec.lansec_titulo + ' - Adicional';
                        prodCat.inf = lansec.lansec_minimo;
                        prodCat.sup = lansec.lansec_maximo
                        prodCat.qtdSelected = 0;
                        prodCat.produtos = lansec.produtosAdic;
                        data.push(helpers.collection().cloneDeep(prodCat));

                    }

                    return true;
                });
            }

            // const categoriaCloned = helpers.collection().cloneDeep(state.categoria);
            const produtoCloned = helpers.collection().cloneDeep(state.produto);

            const {categoriaCodigo, categoriaNome} = produtoCloned;
            produtoCloned.qtdSelected = 1; /*Para iniciar o COMBO ou KIT com {1}*/
            produtoCloned.complementos = data;

            montagem = [
                {
                    categoriaCodigo: categoriaCodigo,
                    categoriaNome: categoriaNome,
                    produtos: [produtoCloned]
                }
            ];
        }
        dispatch({type: 'MONTAGEM_DATA', data: montagem});
    }, [
        dispatch, state.produto,
        store.app.services.pizza, store.app.services.kits,
        store.app.services.produtosCategorias, state.edit
    ]);

    useEffect(() => {
        init()
    }, [init]);

    useEffect(() => {
        dispatch({
            type: 'ANOTHERS', anothers: {
                anchorInicio: false,
                carouselCategorias: {
                    carousel: `#carousel_${helpers.collection().snakeCase(state.produto.categoriaNome)}`,
                    item: `#${helpers.collection().snakeCase(state.produto.produtoNome)}`,
                }
            }
        });
    }, [dispatch, state.produto.categoriaNome, state.produto.produtoNome]);

    const handleObservacao = (e, produto) => {
        produto.produtoObservacao = e.currentTarget.value;
        dispatch({type: 'UPDATE'});
    };

    const handleClickMinus = async (e, produto, complemento) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        if (produto.qtdSelected === 0) {
            return false;
        }
        produto.qtdSelected--;
        complemento.qtdSelected--;
        dispatch({type: 'UPDATE'});
    };

    const handleClickPlus = async (e, produto, complemento) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');

        // if (produto.preco === 0) {
        //   return false;
        // }

        produto.qtdSelected++;
        complemento.qtdSelected++;

        if (complemento.qtdSelected > complemento.sup) {
            complemento.qtdSelected--;
            produto.qtdSelected--;
            helpers.toast.info(`Só pode adicionar no máximo ${complemento.sup} item(s).`);
        }

        if (state.produto.temPizza === 'S' && produto.qtdSelected > 1 && !complemento.adicionais) {
            complemento.qtdSelected--;
            produto.qtdSelected--;
            helpers.toast.info(`Só é possível adicionar um item: ${produto.produtoNome}.`);
        }

        if ((state.produto.pedeComplemento === 'S' && !!state.produto.cComplemento) && produto.qtdSelected > produto.tmax) {
            complemento.qtdSelected--;
            produto.qtdSelected--;
            helpers.toast.info(`Só é possível adicionar ${produto.tmax} iten(s) de ${produto.produtoNome}.`);
        }
        dispatch({type: 'UPDATE'});
    };

    const handleClickAdicionar = () => {

        if (!store.app.services.estabelecimento.em_funcionamento && !helpers.isCardapio()) {
            helpers.toast.info('Por favor, verifique nosso horario de funcionamento, obrigado.');
            return false;
        }

        const validate = [];

        store.app.modules.montagem.data.map(categorias => {
            categorias.produtos.map(produto => {
                if (produto.qtdSelected === '' || produto.qtdSelected < 1) {
                    validate.push({
                        categoria: produto.produtoNome,
                        inf: 1
                    });
                }
                produto.complementos.map(comlemento => {
                    if (comlemento.qtdSelected < comlemento.inf) {
                        validate.push({
                            categoria: comlemento.categoriaNome,
                            inf: comlemento.inf
                        });
                    }
                    return true;
                });
                return true;
            });
            return true;
        });

        if (validate.length > 0) {
            /*Exibe uma mensagem por vez! apenas no inicio do array*/
            /*Isso faz com que não encha a tela de notificação e valide todos os complementos...*/
            const validateMsg = `${validate[0].categoria}: deve ter no mínimo ${validate[0].inf} item(s)`;
            helpers.toast.info(validateMsg);
            return false;
        }

        history.goBack();

        if (!state.editar) {
            const produtos = helpers.collection().cloneDeep(store.app.modules.montagem.data);
            dispatch({type: 'MONTAGEM_DATA', data: []});
            dispatch({type: 'PEDIDO_PRODUTOS_MONTAGEM', produtos});
        }

    };

    if (!store.app.services.loaded || store.app.services.estabelecimento.slug !== idestabelecimento) {
        history.push(`/${idestabelecimento}`);
        return false;
    }

    if (helpers.collection().isEmpty(state.produto)) {
        history.push(`/${idestabelecimento}/home#inicio`);
        return false;
    }

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        )
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    return (
        <div className="d-flex flex-column justify-content-between scroll">
            <div className="min-height-170px flex-grow-0 d-flex flex-column text-white" style={{
                backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
            }}>
                <div className="flex-shrink-0 flex-grow-0 d-flex align-items-center justify-content-between">
                    <div className="p-2"
                         onClick={e => {
                             history.goBack()
                         }}>
                        <IconArrowLeft width={35}/>
                    </div>
                    <div className="pt-2 pr-3 pb-2 pl-2">
                        <IconLogoMenewCircle width={38}/>
                    </div>
                </div>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-0 d-flex align-items-center justify-content-center"
                 style={{marginTop: '-100px'}}>
                <div className="elevation-3" style={{
                    width: "195px",
                    height: "195px",
                    backgroundImage: !!state.produto.produtoFoto
                        ? `url(https://d3tn6f63tltlff.cloudfront.net/old-tmp/${state.produto.produtoFoto})`
                        : 'url(/images/sem-imagem.png)',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: 'repeat',
                    backgroundColor: '#fff',
                    borderRadius: "10px"
                }}/>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">
                <div className="p-2 text-break">
                    <span className="text-lg">
                    {state.produto.produtoNome}
                </span>
                </div>
                <div className="p-2 d-flex align-items-center justify-content-between">
                    <span className="text-xl" style={{
                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                    }}>{helpers.produto.getMenorPrecoProdutoComplemento(state.produto, store)}</span>
                </div>
                <div className="p-2 mt-4 text-justify text-break">
                    <span className="text-muted">
                        {state.produto.produtoDescricao}
                    </span>
                </div>
            </div>

            {
                store.app.modules.montagem.data.map(categorias => (
                    categorias.produtos.map((produto, produtoKey) => (
                        <div key={produtoKey}>
                            {
                                produto.complementos.map((complemento, complementoKey) => (
                                    <div key={complementoKey}>

                                        {/*Sabores quantidade*/}
                                        <div className="p-2 flex-grow-1">
                                            <div
                                                className="d-flex align-items-center justify-content-between text-white border-radius-10"
                                                style={{
                                                    backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                                }}>
                                                <div className="p-1 ml-3 w-75 d-flex flex-column">
                                                    <span>{complemento.categoriaNome}</span>
                                                    <span className="text-xs">Escolha uma opção</span>
                                                </div>
                                                <div className="p-1 mr-3 w-25">
                                                    <span>( {complemento.inf} de {complemento.sup} )</span>
                                                </div>
                                            </div>

                                            {
                                                complemento.produtos.map(produtoComplemento => (
                                                    <div key={produtoComplemento.produtoCodigo} className="flex-fill">
                                                        <div
                                                            className="p-3 d-flex align-items-center justify-content-between">
                                                            <div className="p-2 d-flex flex-column">
                                                                <div>
                                                                    <span>{produtoComplemento.produtoNome}</span>
                                                                </div>
                                                                <div>
                                                                    <span className="text-xs text-muted">
                                                                        {produtoComplemento.produtoDescricao}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                            <span className="text-md" style={{
                                                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                            }}>
                                                                + {helpers.converteMoeda(produtoComplemento.preco)}
                                                            </span>
                                                                </div>
                                                            </div>
                                                            <div className="p-2">
                                                                <div
                                                                    className="width-100px d-flex align-items-center position-relative">
                                                                    <i className="fas fa-minus position-absolute left-0 ml-2 p-1"
                                                                       style={{
                                                                           color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                       }}
                                                                       onClick={e => {
                                                                           handleClickMinus(e, produtoComplemento, complemento)
                                                                       }}/>
                                                                    <div
                                                                        className="form-control form-control-md text-center border-radius-10">
                                                                        {produtoComplemento.qtdSelected}
                                                                    </div>
                                                                    <i className="fas fa-plus position-absolute right-0 mr-2 p-1"
                                                                       style={{
                                                                           color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                       }}
                                                                       onClick={e => {
                                                                           handleClickPlus(e, produtoComplemento, complemento)
                                                                       }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="m-auto"/>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                        {/*Sabores quantidade*/}
                                    </div>
                                ))
                            }

                            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">
                                <div
                                    className="d-flex align-items-center justify-content-between text-white border-radius-10"
                                    style={{
                                        backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                    }}>
                                    <div className="p-2 ml-4">
                                        <span className="text-bold">Observações:</span>
                                    </div>
                                </div>
                                <div className="p-2">
                                        <textarea rows={6} cols={30} className="form-control border-radius-15"
                                                  defaultValue={produto.produtoObservacao}
                                                  onBlur={e => {
                                                      handleObservacao(e, produto)
                                                  }}
                                                  id="produtoObservacao" placeholder="Observações..."/>
                                </div>
                            </div>

                        </div>
                    ))
                ))
            }

            {/*Sabores CheckBox*/}
            {/* <div className="p-2 flex-grow-1">
                <div className="d-flex align-items-center justify-content-between text-white border-radius-10"
                     style={{
                         backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                     }}>
                    <div className="p-2 ml-4 d-flex flex-column">
                        <span>Sabores</span>
                        <span className="text-xs">Escolha uma opção</span>
                    </div>
                    <div className="p-2 mr-4">
                        <span>( 1 de 1 )</span>
                    </div>
                </div>
                <div className="flex-fill">
                    <div className="p-3 d-flex align-items-center justify-content-between">
                        <div className="p-2 d-flex flex-column">
                            <div>
                                <span>Tradicional</span>
                            </div>
                            <div>
                                <span className="text-xs text-muted">sem recheio</span>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="icheck-danger d-inline">
                                <input type="radio" name="r2" id="radioDanger1" defaultChecked={''}/>
                                <label htmlFor="radioDanger1"/>
                            </div>
                        </div>
                    </div>
                    <hr className="m-auto"/>
                </div>
                <div className="flex-fill">
                    <div className="p-3 d-flex align-items-center justify-content-between">
                        <div className="p-2 d-flex flex-column">
                            <div>
                                <span>Catupiry</span>
                            </div>
                            <div>
                                <span className="text-xs text-muted">Borda recheada com catupiry</span>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="icheck-danger d-inline">
                                <input type="radio" name="r2" id="radioDanger2" defaultChecked={''}/>
                                <label htmlFor="radioDanger2"/>
                            </div>
                        </div>
                    </div>
                    <hr className="m-auto"/>
                </div>
            </div>*/}
            {/*Sabores CheckBox*/}

            <div className="p-3 mb-n2 flex-shrink-0 d-flex align-items-center justify-content-between"
                 style={{
                     backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                 }}>
                <div className="p-2 mb-2">
                    <span className="text-white text-bold">Quantidade:</span>
                </div>
                <div className="p-2 mb-2">
                    <div className="width-100px d-flex align-items-center position-relative">
                        <i className="fas fa-minus position-absolute left-0 ml-2 p-1" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}
                           onClick={async e => {
                               await helpers.animatedElement(e, 'bounceIn', 'fast');
                               helpers.montagem.setMinusTotalCombo(store, dispatch);
                           }}/>
                        <div className="form-control form-control-md text-center border-radius-10">
                            {helpers.montagem.getQuantidadeTotalCombo(store)}
                        </div>
                        <i className="fas fa-plus position-absolute right-0 mr-2 p-1" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}
                           onClick={async e => {
                               await helpers.animatedElement(e, 'bounceIn', 'fast');
                               helpers.montagem.setPlusTotalCombo(store, dispatch);
                           }}/>
                    </div>
                </div>
            </div>

            <div
                className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-around border-top-radius-10 bg-white">
                <div className="text-bold text-md btn btn-block"
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.goBack();
                     }}>
                    Cancelar
                </div>
                <div className="p-2 text-bold text-md btn btn-block border-radius-20
                 d-flex align-items-center justify-content-around" style={{
                    backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                    color: '#fff',
                }}
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         handleClickAdicionar();
                     }}>
                    <div className="p-2">Adicionar</div>
                    <div className="p-2">{helpers.montagem.getTotalCombo(store)}</div>
                </div>
            </div>

        </div>
    );
};

export default ProdutoComplemento;
